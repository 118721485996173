import * as React from 'react';
import Head from 'next/head';
import Nav from 'components/Nav';
import { NavBarAction } from 'interfaces';

type Props = {
  title?: string;
  description?: string;
  url?: string | undefined;
  navBarActions?: NavBarAction[];
  mainNavBarAction?: NavBarAction;
};

const Layout: React.FunctionComponent<Props> = ({
  children,
  title = 'Welcome to your Lounge!',
  description = 'Play board games like Codenames without the board. All you need are your friends and your phones.',
  url,
  navBarActions = [],
  mainNavBarAction,
}) => {
  const [localURL, setLocalURL] = React.useState(url);

  React.useEffect(() => {
    if (!localURL) {
      setLocalURL(window.location.href);
    }
  }, []);

  return (
    <div>
      <Head>
        <title>{title}</title>
        <meta charSet="utf-8" />

        <meta
          name="viewport"
          content="initial-scale=1.0, maximum-scale=1.0, width=device-width, user-scalable=no"
        />

        <meta name="description" content={description} key="description" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={title} key="og-title" />
        <meta
          property="og:description"
          content={description}
          key="og-description"
        />
        <meta property="og:url" content={localURL} key="og-url" />
      </Head>

      <header className="relative z-20">
        <Nav actions={navBarActions} mainAction={mainNavBarAction} />
      </header>

      <main className="max-w-2xl mx-auto sm:px-6">
        <div className="px-4 py-4 sm:px-0">{children}</div>
      </main>
    </div>
  );
};

export default Layout;
