import { useState } from 'react';
import Link from 'next/link';
import cn from 'classnames';
import { NavBarAction } from 'interfaces';

enum LinkType {
  INTERNAL = 'internal',
  EXTERNAL = 'external',
}

type LinkData = {
  label: string;
  href: string;
  type: LinkType;
};

const links: LinkData[] = [
  { label: 'Settings', href: '/settings', type: LinkType.INTERNAL },
];

type Props = {
  actions: NavBarAction[];
  mainAction?: NavBarAction;
};

const Nav = ({ actions, mainAction }: Props) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <nav className="bg-gray-800">
      {/* This is the config for desktop (md) and above */}
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-16">
          <div className="flex items-center">
            <div className="flex-shrink-0">
              {mainAction ? (
                <h1
                  className="cursor-pointer text-lg font-semibold text-white"
                  onClick={mainAction.onClick}
                >
                  {mainAction.label}
                </h1>
              ) : (
                <a href="https://getlounge.app" target="_blank">
                  <h1 className="cursor-pointer text-lg font-semibold text-white">
                    Lounge
                  </h1>
                </a>
              )}
            </div>
            <div className="hidden md:block">
              <div className="ml-10 flex items-baseline">
                {links.map(({ label, href, type }, index) => (
                  <Link
                    href={href}
                    key={`${href}`}
                    passHref={type === LinkType.EXTERNAL ? true : false}
                  >
                    <a
                      className={cn(
                        'px-3 py-2 rounded-md text-sm font-medium text-gray-300 hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700',
                        { 'ml-4': index !== 0 },
                      )}
                    >
                      {label}
                    </a>
                  </Link>
                ))}
                {actions.map(({ label, onClick }, index) => (
                  <a
                    className={cn(
                      'px-3 py-2 rounded-md text-sm font-medium text-gray-300 hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700 ml-4 cursor-pointer',
                    )}
                    onClick={onClick}
                    key={`${label}${index}`}
                  >
                    {label}
                  </a>
                ))}
              </div>
            </div>
          </div>

          {/* Mobile menu button */}
          <div className="-mr-2 flex md:hidden">
            <button
              className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:bg-gray-700 focus:text-white"
              onClick={() => setIsOpen(!isOpen)}
            >
              {/* Hamburger menu icon*/}
              <svg
                className={cn(
                  'h-6 w-6',
                  { hidden: isOpen === true },
                  { block: isOpen === false },
                )}
                stroke="currentColor"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16M4 18h16"
                />
              </svg>

              {/* Close icon */}
              <svg
                className={cn(
                  'h-6 w-6',
                  { hidden: isOpen === false },
                  { block: isOpen === true },
                )}
                stroke="currentColor"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>

      {/* Mobile menu */}
      <div
        className={cn(
          'md:hidden',
          { block: isOpen === true },
          { hidden: isOpen === false },
        )}
      >
        <div className="px-2 pt-2 pb-3 sm:px-3">
          {links.map(({ label, href, type }, index) => (
            <Link
              href={href}
              key={`${href}`}
              passHref={type === LinkType.EXTERNAL ? true : false}
            >
              <a
                className={cn(
                  'block px-3 py-2 rounded-md text-base font-medium text-gray-300 hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700',
                  { 'mt-1': index !== 0 },
                )}
              >
                {label}
              </a>
            </Link>
          ))}
          {actions.map(({ label, onClick }, index) => (
            <a
              className={cn(
                'block px-3 py-2 rounded-md text-base font-medium text-gray-300 hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700 mt-1 cursor-pointer',
              )}
              onClick={onClick}
              key={`${label}${index}`}
            >
              {label}
            </a>
          ))}
        </div>
      </div>
    </nav>
  );
};

export default Nav;
